<template>
  <div class="contact_content_container w">
    <div class="title">
      上海泉邦网络科技有限公司
    </div>
    <div class="phone">
      <span class="iconfont icon-dianhua"></span>
      <div class="content">
        电话：4009997507
      </div>
    </div>
    <div class="zipcode">
      <span class="iconfont icon-youbian"></span>
      <div class="content">
        邮编：201802
      </div>
    </div>
    <div class="email">
      <span class="iconfont icon-youxiang"></span>
      <div class="content">
        邮箱：846768447@qq.com
      </div>
    </div>
    <div class="address">
      <span class="iconfont icon-daohangdizhi"></span>
      <div class="content">
        地址：上海市嘉定区银翔路819号中暨大厦3楼泉邦集团
      </div>
    </div>
    <div class="map">
      <img src="@/assets/images/about/about_map.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactContent'
}
</script>

<style lang="less" scoped>
.contact_content_container {
  position: relative;
  padding: 85px 0px 110px 183px;
  .phone,
  .zipcode,
  .email,
  .address {
    display: flex;
    align-items: center;
    margin-bottom: 84px;
  }
  .title {
    font-size: 64px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #024580;
    line-height: 89px;
    margin-bottom: 60px;
  }
  span {
    display: block;
    width: 91px;
    height: 91px;
    line-height: 91px;
    background: #1651dd;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    font-size: 50px;
    margin-right: 43px;
  }
  .content {
    font-size: 48px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #024580;
  }
  .map {
    position: absolute;
    top: 235px;
    left: 981px;
    width: 757px;
    height: 501px;
  }
}
</style>
