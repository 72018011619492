<template>
  <div class="contact_container">
    <!-- 顶部导航占位符 -->
    <QbTopnavPlaceholder></QbTopnavPlaceholder>

    <!-- 标题 -->
    <QbTitle :title="title" :complement="complement"></QbTitle>

    <!-- 内容 -->
    <ContactContent></ContactContent>
  </div>
</template>

<script>
import ContactContent from './components/contact-content.vue'
export default {
  name: 'Contact',
  data() {
    return {
      title: '泉邦集团联系方式',
      complement: 'Contact information of Quanbang group'
    }
  },
  components: {
    ContactContent
  }
}
</script>

<style lang="less" scoped></style>
